a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}
@media (min-width: 768px) {
    html {
        font-size: 24px;
    }
}
.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
.navi-link {
    padding: 0.5rem 1rem;
    margin: 0.3rem;
    text-decoration: none;
}
    .navi-link.button:hover,
    .navi-link.button.active {
        color: #fff;
        background-color: #13207C;
        border-radius: 3px;
    }
.nav-header {
    font-size: 16px;
    padding-top: 0;
    padding-bottom: 0;
}
    .nav-header a {
        background-repeat: no-repeat;
        background-position: left;
        background-color: #fff;
    }
        .nav-header a.email {
            padding-left: 1.3rem;
            background-image: url('../../public/images/email_v2.svg');
        }
        .nav-header a.call {
            padding-left: 1rem;
            background-image: url('../../public/images/call_v2.svg');
        }
