.oferta {
    margin: 2rem 0;
}
.oferta h6 {
    text-align: center;
    font-weight: bold;
}
.oferta-header {
    width: 100%;
    height: 2rem;
}
.oferta-header-left {
    float: left;
    font-weight: 600;
}
.oferta-header-right {
    float: right;
}
.oferta-address {
    font-style: italic;
}