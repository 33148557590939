.contact-form-title {
    padding: 0.5rem 0;
}
form {
    display: flex;
    flex-wrap: wrap;
}
form label {
    flex: 0 0 50%;
    font-size: 0.8rem;
    padding: 0.25rem 2rem 0.25rem 0;
    color: #444;
}
form label.human {
    display: none;
}
form label .red {
    color: #f00;
}
form .flex-100 {
    flex: 0 0 100%;
}
form .checkbox input {
    display: inline;
    width: 2rem;
}
form input, form textarea {
    display: block;
    width: 100%;
}
form .form-submit input {
    background-color: #c5242b;
    color: #fff;
    padding: 0 2rem;
    margin: 1rem 0;
    border-radius: 0.3rem;
}
form .form-submit input:hover {
    opacity: 0.8;
}
    form .error-text {
        color: #c5242b;
        font-weight: bold;
        text-align: center;
    }
form .success-text {
    color: #458b31;
    font-weight: bold;
    text-align: center;
}
