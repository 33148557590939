.main-page {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #fff;
    background-image: url('../../public/images/test0.jpeg');
    text-align: center;
}
@media (min-width: 400px) and (max-width: 900px) {
    .main-page {
        height: 500px;
    }
}
@media (min-width: 901px) and (max-width: 1024px) {
    .main-page {
        height: 600px;
    }
}
@media (min-width: 1025px) and (max-width: 1280px) {
    .main-page {
        height: 700px;
    }
}
@media (min-width: 1281px) and (max-width: 1600px) {
    .main-page {
        height: 800px;
    }
}
@media (min-width: 1601px) and (max-width: 1920px) {
    .main-page {
        height: 900px;
    }
}
@media (min-width: 1921px) and (max-width: 2560px) {
    .main-page {
        height: 1000px;
    }
}
@media (min-width: 2561px) {
    .main-page {
        height: 1100px;
    }
}
.main-page-container, .main-page center {
    color: #fff;
    width: 100%;
    height: 100%;
}
.main-page-place-holder {
    background-color: rgba(19,32,124,0.6);
    padding: 7rem 1rem 1rem 1rem;
    width: 100%;
    height: 100%;
}
.main-page-title {
    margin-bottom: 1rem;
    font-size: 2.5rem;
}
.main-page-text {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}
.main-page-buttons {
    padding: 1rem 0;
}
.main-page-buttons a, .main-page-buttons a:hover {
    margin: 0 1rem;
    padding: 0.4rem 1rem 0.5rem 1rem;
    color: #fff;
    text-decoration: none;
    background-color: #d2091e;
    border-radius: 0.5rem;
    width: 13rem;
    display: inline-block;
}
.main-page-buttons a:hover {
    opacity: 0.9;
}