a, a:hover, h1, h2, h3, h4 {
    color: #13207C;
}
a:hover {
    opacity: 0.8;
}
.container {
    max-width: 90%;
}
.footer {
    font-size: 0.8rem;
}
.footer-container {
    display: flex;
}
.footer-container, .footer a {
    background-color: #565656;
    color: #fff;
}
.footer a {
    background-repeat: no-repeat;
    background-position: left;
    background-color: transparent;
}
    .footer a.email {
        padding-left: 1.3rem;
        background-image: url('../../public/images/email_white.svg');
    }
    .footer a.call {
        padding-left: 1rem;
        background-image: url('../../public/images/call_white.svg');
    }
.footer .footer-address, .footer .footer-contacts {
    width: 50%;
}
.footer h4 {
    color: #fff;
}
.footer-copyright {
    color: #13207C;
}
.footer-copyright, .footer h4 {
    width: 100%;
    text-align: center;
}
.footer-body {
    padding: 1rem;
}
.footer-border {
    border-right: 1px solid #888;
}